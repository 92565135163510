import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/index",
    name: "index",
    meta: { static: true, inner: true },
    component: () => import("@/views/index/IndexView.vue"),
  },
  {
    path: "/",
    name: "main",
    redirect: "/home",
    meta: { keepAlive: false },
    component: () => import("@/views/layout/LayoutView.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        alias: "/",
        meta: { keepAlive: false },
        component: () => import("@/views/home/index.vue"),
      },
      {
        path: "/busCooperation",
        name: "busCooperation",
        meta: { keepAlive: false },
        component: () => import("@/views/busCooperation/index.vue"),
      },
      {
        path: "/news",
        name: "news",
        meta: { keepAlive: true },
        component: () => import("@/views/news/index.vue"),
      },
      {
        path: "/news/detail/:type/:id/:rownum",
        name: "newsDetail",
        meta: { keepAlive: false },
        component: () => import("@/views/news/detail.vue"),
      },
      {
        path: "/product",
        name: "product",
        meta: { keepAlive: false },
        component: () => import("@/views/product/index.vue"),
      },
    ],
  },
  // 添加NotFound路由
  {
    path: "/:catchAll(.*)",
    component: () => import("@/views/index/NotFoundView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 };
  },
});

// 路由前
router.beforeEach((to, from, next) => {
  next();
});

export default router;
